import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import qs from 'qs';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import { useLocaleContext } from '../../../context/Locale';
import RouteKeys from '../../../../config/RouteKeys';

type Props = PageProps & WithTranslation;

const InstantVerification: React.FC<Props> = (props) => {
  const { activeLocaleTag } = useLocaleContext();
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  const reset = () => {
    setHasError(false);
    setHasSuccess(false);
  };

  useEffect(() => {
    const bsvCode = localStorage.getItem('bsvCode');
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (bsvCode) {
      fetch(`${process.env.GATSBY_INSTANT_VERIFICATION_API_URL}/bankingInfo`, {
        method: 'POST',
        // @ts-ignore
        headers: {
          'Content-Type': 'application/json',
          client_id: process.env.GATSBY_LENDING_AREAS_CLIENT_ID,
          client_secret: process.env.GATSBY_LENDING_AREAS_CLIENT_SECRET,
        },
        body: JSON.stringify({
          bsvCode,
          consentTime: new Date().toISOString(),
          ...params,
        }),
      })
        .then(() => {
          setHasSuccess(true);
        })
        .catch(() => {
          setHasError(true);
          localStorage.removeItem('bsvCode');
        });
    } else {
      setHasError(true);
    }

    return reset;
  }, []);

  const { t, location } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('instantVerification.seo.title'),
    },
    page: {
      title: hasSuccess
        ? t('instantVerification.thankYou.heading')
        : t('instantVerification.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const getButtonLink = () => {
    return `${location.origin}/${activeLocaleTag}${
      RouteKeys.INSTANT_VERIFICATION.INDEX.path[activeLocaleTag]
    }?id=${localStorage.getItem('bsvCode') || ''}&skipVerification=true`;
  };

  return (
    <Layout options={layoutOptions} className="InstantVerification">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-80">
            {hasSuccess ? (
              <>
                <p>{t('instantVerification.thankYou.body1')}</p>
                <ButtonLink
                  to={getButtonLink()}
                  aLinkProps={{
                    target: '_parent',
                    rel: '',
                  }}
                  styleOptions={{
                    isInline: true,
                    invertColors: true,
                  }}
                  linkOptions={{ isExternalLink: true }}
                >
                  {t('instantVerification.thankYou.buttonLabel')}
                </ButtonLink>
                <br />
                <br />
                <p>
                  <Trans t={t} i18nKey="instantVerification.thankYou.body2" />
                </p>
              </>
            ) : null}
            {hasError ? (
              <ErrorMessage>
                {t('instantVerification.form.submissionValidation.error')}
              </ErrorMessage>
            ) : null}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.INSTANT_VERIFICATION)(InstantVerification);
